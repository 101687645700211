import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import FacultyPersonalTagForm from './FacultyPersonalTagForm';
import { useAuthenticationState } from '../../context/Auth.context';

function FacultyPersonalTag() {
    const { user } = useAuthenticationState();

    const fetchUtils = useAxiosGet(endpoints.alumniPersonalTag.get);
    const fetchUtilsWithFilteredData = {
        ...fetchUtils,
        response: fetchUtils.response?.filter(
            (item) => item.TYPE === 'FACULTY'
        ),
    };

    const cellModifier = {
        MAPPING: ({ value }) => {
            // Extract unique tag names using Set
            const uniqueTags = [
                ...new Set(value?.map((tag) => tag?.Alumni_Personal_Tag_Name)),
            ];

            return (
                <div
                    style={{
                        maxWidth: '500px',
                        overflow: 'auto',
                        paddingBottom: '5px',
                    }}
                >
                    {uniqueTags?.map((tagName) => (
                        <div
                            key={tagName}
                            className='p-2 me-2 d-inline-block small'
                            style={{
                                background: 'rgba(95, 179, 246, 0.1)',
                                borderRadius: '10px',
                            }}
                        >
                            {tagName}
                        </div>
                    ))}
                </div>
            );
        },
    };

    return (
        <>
            <CRUDTable
                endpoints={endpoints.alumniPersonalTag}
                Form={FacultyPersonalTagForm}
                {...viewProps.FacultyPersonalTag}
                cellModifier={cellModifier}
                addBtnLabel='Add Mapping'
                formSize='md'
                irremovable
                immutable={[
                    'STUDENTCOUNCIL',
                    'CLASSREPRESENTATIVE',
                    'CLASSREP + STUDENT COUNCIL',
                ].includes(user.Role)}
                insertable={
                    ![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREP + STUDENT COUNCIL',
                    ].includes(user.Role)
                }
                // {...fetchUtils}
                {...fetchUtilsWithFilteredData}
            />
        </>
    );
}

export default FacultyPersonalTag;
