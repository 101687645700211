import React, { useState } from 'react';
import PaginatedTable from '../../components/table/PaginatedTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import CityChapterForm from './CityChapterAlumniMapForm';
import { useLocation } from 'react-router-dom';
import DeleteWithWarning from '../../components/DeleteWithWarning';
import withAlert from '../../components/withAlert';
import { useAuthenticationState } from '../../context/Auth.context';
import DownloadExcelWithWantedColumns from '../../components/DownloadExcelWithWantedColumns';
import Loader from '../../components/Loader';

function CityChapterAlumniMap({ showAlert }) {
    const { state } = useLocation();

    const { CityChapter_ID: cityId, CityChapter_Name: cityChap } = state;
    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);

    const url = `${endpoints.cityChapterAlumniMap.get}/${cityId}?page=${page}&limit=10`;
    const fetchUtils = useAxiosGet(url, { preventCall: !triggerApiCall });

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const { user } = useAuthenticationState();

    const cellModifier = {
        Delete: ({ row }) => (
            <div className='td text-center'>
                <DeleteWithWarning
                    title={'Alumni from City Chapter'}
                    configBody={{
                        Alumni_ID: row.original.ALUMNI_BATCH_ADM_CODE,
                        CityChapter_ID: cityId,
                    }}
                    className='mx-auto'
                    onAfterDelete={() => {
                        fetchUtils.reFetch();
                        showAlert(
                            'success',
                            'The alumni has been removed successfully from the city chapter'
                        );
                    }}
                    endpoint={endpoints.cityChapterAlumniMap.delete}
                />
            </div>
        ),
    };

    if (fetchUtils.loading) {
        return <Loader />;
    }
    // console.log(fetchUtils.response.pagination);
    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.cityChapterAlumniMap}
                Form={CityChapterForm}
                {...viewProps.CityChapterAlumniMap}
                relevants={[
                    ...(viewProps.CityChapterAlumniMap.relevants || []),
                    ...(![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREP + STUDENT COUNCIL',
                    ].includes(user.Role)
                        ? ['Delete']
                        : []),
                ]}
                columnHeads={[
                    ...(viewProps.CityChapterAlumniMap.columnHeads || []),
                    ...(![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREP + STUDENT COUNCIL',
                    ].includes(user.Role)
                        ? ['Delete']
                        : []),
                ]}
                {...fetchUtils}
                immutable
                response={fetchUtils.response.responseData}
                name={`City Chapter User Mapping - ${cityChap}`}
                cellModifier={cellModifier}
                addBtnLabel={`Add User to City Chapter`}
                irremovable
                insertable={
                    ![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREP + STUDENT COUNCIL',
                    ].includes(user.Role)
                }
                downloadable={false}
                headerExtras={
                    <DownloadExcelWithWantedColumns
                        downloadFileName={`City Chapter User Mapping - ${cityChap}`}
                        relevants={viewProps.CityChapterAlumniMap.relevants}
                        unwantedRelevant={['Delete']}
                        columnHeads={viewProps.CityChapterAlumniMap.columnHeads}
                        response={fetchUtils.response.responseData}
                    />
                }
            />
        </>
    );
}

export default withAlert(CityChapterAlumniMap);
