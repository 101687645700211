import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import BatchHeadForm from './BatchHeadForm';

function BatchHeads() {
    const fetchUtils = useAxiosGet(endpoints.batchHeads.get);

    return (
        <>
            <CRUDTable
                endpoints={endpoints.batchHeads}
                Form={BatchHeadForm}
                {...viewProps.BatchHeads}
                {...fetchUtils}
            />
        </>
    );
}

export default BatchHeads;
