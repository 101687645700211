import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import endpoints from '../../endpoints';
import axios from 'axios';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';

function FacultyPersonalTagForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    const { response, loading } = useAxiosGetMultiple({
        alumni: endpoints.alumniDetail.get,
        personalTag: endpoints.personalTags.get,
    });

    const { alumni, personalTag } = response;
    const alumniOnly = alumni.filter((alumnus) => Number(alumnus.Type) === 3);

    const submitHandler = (values, { setSubmitting }) => {
        values.operation = updateValues ? 'update' : 'add';
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally((res) => setSubmitting(false));
    };

    const initialFormValues = {
        ALUMNI_PERSONALTAG_ID_ARRAY: updateValues?.MAPPING || [],
        ALUMNI_BATCH_ADM_CODE: updateValues?.ALUMNI_BATCH_ADM_CODE || '',
        Email: '',
        Year_of_graduation: '',
    };

    if (loading) {
        return <Loader />;
    }

    const preSelectedOption = {};

    if (updateValues) {
        preSelectedOption.alumniOnly = updateValues;
        preSelectedOption.Email = updateValues.PERSONAL_EMAILID;
        preSelectedOption.Year_of_graduation = updateValues.YearOfGraduation;
        preSelectedOption.personalTag = personalTag.filter(
            (personalTag) =>
                !!initialFormValues?.ALUMNI_PERSONALTAG_ID_ARRAY?.find(
                    (tag) =>
                        Number(tag.Alumni_PersonalTag_ID) ===
                        Number(personalTag.Alumni_PersonalTag_ID)
                )
        );
    }
    personalTag?.map((tag) => (tag.isFixed = tag.personal_tag_flag === 2));
    preSelectedOption?.personalTag?.map(
        (tag) => (tag.isFixed = tag.personal_tag_flag === 2)
    );

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                ALUMNI_BATCH_ADM_CODE: Yup.string().required(),
                ALUMNI_PERSONALTAG_ID_ARRAY: Yup.array(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <FormikControl
                        control='select-advanced'
                        required
                        name='ALUMNI_BATCH_ADM_CODE'
                        label='FACULTY'
                        options={alumniOnly}
                        optionValue='ALUMNI_BATCH_ADM_CODE'
                        defaultValue={preSelectedOption.alumniOnly}
                        optionLabel='ALUMNI_Name'
                        disabled={!!updateValues}
                        onChange={(selected) => {
                            setFieldValue(
                                'ALUMNI_BATCH_ADM_CODE',
                                selected.ALUMNI_BATCH_ADM_CODE
                            );
                            setFieldValue('Email', selected.PERSONAL_EMAILID);
                            setFieldValue(
                                'Year_of_graduation',
                                selected.YearOfGraduation
                            );
                        }}
                    />
                    {!updateValues && (
                        <>
                            <FormikControl
                                control='input'
                                label='Email'
                                name='Email'
                                disabled
                            />
                            <FormikControl
                                control='input'
                                label='Year of Graduation'
                                name='Year_of_graduation'
                                disabled
                            />
                        </>
                    )}

                    {!updateValues ? (
                        <FormikControl
                            disabled={!values.ALUMNI_BATCH_ADM_CODE}
                            keyProp='Alumni_PersonalTag_ID'
                            control='select-advanced'
                            name='ALUMNI_PERSONALTAG_ID_ARRAY'
                            label='Personal Tags'
                            options={personalTag.filter((tag) => !tag.isFixed)}
                            defaultValue={preSelectedOption.personalTag}
                            isMulti
                            optionValue='Alumni_PersonalTag_ID'
                            optionLabel='Alumni_Personal_Tag_Name'
                        />
                    ) : (
                        <FormikControl
                            control='SelectWithFixedOptions'
                            disabled={!values.ALUMNI_BATCH_ADM_CODE}
                            keyProp='Alumni_PersonalTag_ID'
                            name='ALUMNI_PERSONALTAG_ID_ARRAY'
                            label='Personal Tags'
                            options={personalTag}
                            hideOptionProperty='isFixed'
                            defaultValue={preSelectedOption.personalTag}
                            isMulti
                            optionValue='Alumni_PersonalTag_ID'
                            optionLabel='Alumni_Personal_Tag_Name'
                        />
                    )}
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(FacultyPersonalTagForm);
