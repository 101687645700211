import React from 'react';
import Menu from './Menu';
import { ReactComponent as Logo } from '../../asset/icons/Logo.svg';
import { useAuthenticationState } from '../../context/Auth.context';
import NewMenu from './NewMenu';

function Sidebar({ routes }) {
    const { user } = useAuthenticationState();
    // console.log(user.Roles, 'sidebar');

    const sidebarStyle = {
        width: '230px',
        position: 'fixed',
        height: '100vh',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        // transform: `translateX(${show ? "0%" : "-100%"})`,
        transition: 'transform .25s',
        fontSize: '12px',
    };

    return (
        <div style={sidebarStyle}>
            <div
                className=' pt-4 ps-4 text-white'
                style={{
                    height: '70px',
                    background:
                        'linear-gradient(98.23deg, #5FB3F6 8.77%, rgba(255, 255, 255, 0) 205.16%)',
                }}
            >
                <Logo />
            </div>
            <div
                style={{
                    height: 'calc(100vh - 90px)',
                    overflowY: 'auto',
                }}
                className='py-4 px-3'
            >
                {user.Roles.includes('ADMIN') ? (
                    <Menu routes={routes} />
                ) : (
                    <NewMenu routes={routes} />
                )}
            </div>
        </div>
    );
}

export default Sidebar;
