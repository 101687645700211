import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import StudentCouncilForm from './StudentCouncilForm';
import objCloneKeyValue from '../../helpers/objCloneKeyValue';
import { useAuthenticationState } from '../../context/Auth.context';

function StudentCouncil() {
    const fetchUtils = useAxiosGet(
        endpoints.studentCouncil.get + '?page=1&limit=1000'
    );
    const clonedData = objCloneKeyValue(
        fetchUtils.response,
        'student_council_member_id',
        'studentCouncilMemberId'
    );

    const { user } = useAuthenticationState();

    return (
        <>
            <CRUDTable
                endpoints={endpoints.studentCouncil}
                {...viewProps.StudentCouncil}
                Form={StudentCouncilForm}
                {...fetchUtils}
                response={clonedData}
                addBtnLabel='Add Member'
                immutable
                irremovable={!user.Role?.toLowerCase() === 'institutemember'}
            />
        </>
    );
}

export default StudentCouncil;
