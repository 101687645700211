import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';

function Promos() {
    const fetchUtils = useAxiosGet(endpoints.promos.get);

    const cellModifier = {
        Alumni_PromoCategory: ({ value }) => {
            switch (Number(value)) {
                case 1:
                    return 'Startup';
                case 2:
                    return 'General';
                case 3:
                    return 'Manufacturing';
                case 1:
                    return 'Networking';
                case 2:
                    return 'Financing';

                default:
                    return value;
            }
        },
    };

    return (
        <>
            <CRUDTable
                {...viewProps.Promos}
                // cellModifier={cellModifier}
                irremovable
                immutable
                {...fetchUtils}
            />
        </>
    );
}

export default Promos;
