import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

function EntreInd({ data }) {
    const labels = data.map((entre) => entre.ALUMNI_Company_Domain);
    const counts = data.map((entre) => entre.COUNT_ALUMNI);

    const fluorescentBg = [
        'rgba(255, 99, 132, 0.6)',
        'rgba(54, 162, 235, 0.6)',
        'rgba(255, 159, 64, 0.6)',
        'rgba(102, 255, 102, 0.6)',
        'rgba(75, 192, 192, 0.6)',
        'rgba(153, 102, 255, 0.6)',
        'rgba(255, 204, 51, 0.6)',
        'rgba(204, 0, 255, 0.6)',
        'rgba(204, 255, 0, 0.6)',
        'rgba(16, 230, 232, 0.6)',
        'rgba(0, 247, 0, 0.6)',
        'rgba(150, 150, 150, 0.6)',
        'rgba(247, 232, 2, 0.6)',
        'rgba(246, 60, 0, 0.6)',
    ];

    const fluorescentBorder = fluorescentBg.map((color) =>
        color.replace('0.6', 1)
    );

    const entreIndustries = {
        labels: labels,
        datasets: [
            {
                data: counts,
                backgroundColor: fluorescentBg,
                borderColor: fluorescentBorder,
                borderWidth: 2,
                spacing: 1,
                radius: '75%',
            },
        ],
    };

    return (
        <>
            <div className='small h6 text-center text-dark'>Active Entrepreneurs</div>

            <div
                className='text-muted text-center'
                style={{ fontSize: '.75em' }}
            >
                Industry Basis
            </div>
            <div className='py-4 bg-white rounded-smoother mt-3 card-shadow'>
                {entreIndustries.labels?.length === 0 ? (
                    <div className='p-5 text-center text-muted'>
                        No Entrepreneurs has been Registered so far
                    </div>
                ) : (
                    <Doughnut
                        data={entreIndustries}
                        options={{
                            plugins: {
                                legend: {
                                    position: 'bottom',
                                },
                            },
                        }}
                    />
                )}
            </div>
        </>
    );
}

export default EntreInd;
